* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background: #000;
}

.w3-container {
	max-width: 650px;
	margin: 0 auto;
	overflow-x: hidden;
}

/* div#home {
	padding-bottom: 100px;
	background: #d7d0f3;
	height: 100vh;
} */
img.header-img {
	width: 22px;
}

.gap.d-flex {
	gap: 10px;
}

.phonepe-carousel {
	margin-top: 87px;
}


.card.g {
	background: #000 !important;
	background-image: linear-gradient(#f450500d, #b2090969) !important;
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	background-color: #fff;
	background-clip: border-box;
	position: relative;
	margin-bottom: 0.5rem;
	width: 100%;
	border-radius: 8px;
}

.card-body.d-flex {
	justify-content: space-between;
}

p.mb-1 {
	font-size: 17px;
	color: white;
}

span#paidTeam {
	margin-left: 6px;
	color: #fff;
}

li.active_member {
	width: 100%;
	text-align: center;
	padding: 6px;
	border-radius: 7px;
	background-image: linear-gradient(#f450500d, #b2090969) !important;
	box-shadow: 0 3px 1px 0 rgb(255 0 0 / 45%);
	border: 1px #ffa8a8 solid;
}

.active_member span {
	color: #fff;
	font-weight: 500;
	/* padding-top: 7px; */
	display: block;
	font-size: 15px;
	text-align: left;
	display: flex;
	justify-content: space-between;
}

span#Team_right {
	background: #00800052;
	padding: 8px 9px;
	border-radius: 60px;
	color: green;
	font-size: 12px;
}

.main-btn {
	width: 650px;
	margin: auto;
	max-width: 100%;
	padding: 8px 10px;
	text-align: center;
	/* background: #f2ecfc !important; */
	border-radius: 8px;
	padding: 2px !important;
	border-radius: 7px !important;
}

.main_container_box.mt-5 {
	background: #d8d1f4;
}

h2.Money-transfer {
	font-size: 13px;
	text-align: left;
	font-weight: 800;
}

.main-btn ul {
	display: flex;
	/* margin: 0px; */
	padding: 0px;
	gap: 5px;
	justify-content: space-between;
	text-align: center;
}

li.btnn_blue {
	box-shadow: 0 0px 1px 0 #febd3f;
	background-size: cover;
	background-repeat: no-repeat;
	border: 1px #febd3f solid;
	border-radius: 7px;
	padding: 8px 0px;
	width: 125px;
	text-align: center;
}

ul {
	list-style: none;
	padding: 0px;
}

.main-btn a i {
	max-width: 50px;
	text-align: center;
	display: block;
	margin: auto;
	color: #b4a2d3 !important;
}

.main-btn span {
	font-weight: 500;
	display: block;
	font-size: 11px;
	color: #febd3f !important;
	/* margin-left: 8px; */

}

a.inner-btn {
	text-decoration: none;
}

.card {
	background-color: transparent;
}

.card.dark {
	color: #fff;
}

.card.card.bg-light-subtle .card-title {
	color: dimgrey;
}

.card img {
	width: 80px;
	padding: 0.5em;
	border-radius: 0.7em;
}

h5.card-title {
	font-size: 12px;
	color: #ffffff70;
}

p.card-text {
	font-size: 14px;
	color: #373737;
}

h4 {
	margin: 0%
}

.productBox {
	height: 125px;
	padding: 0.4%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2%;
	background-color: rgb(98 108 210 / 30%);
	border: 1px solid #626cd2;
	border-radius: 20px;
	cursor: pointer;
}

.productBox img {
	height: 50%;
	object-fit: contain;
	margin-top: 1%;
}

.productBox span {
	font-size: small;
	text-align: start;
	width: 100%;
	color: #9b9b9b;
	padding: 0 5px;
}

.productBox h4 {
	font-weight: 400;
	text-align: start;
	width: 90%;
	font-size: 15px;
}

.productBox p {
	max-width: 90%;
	word-break: break-all;
	font-size: small;
	text-align: start;
}

button.data_ed {
	background-color: red;
	font-size: 10px;
	border-radius: 22px;
	color: white;
	padding: 5px 10px;
}

.item_text {
	color: #fff;
	font-size: 19px;
}

.footer-tab {
	position: fixed;
	bottom: 0px;
	max-width: 100%;
	width: 650px;
	left: 50%;
	display: -webkit-flex;
	align-items: flex-end;
	transform: translate(-50%);
	height: 55px;
	z-index: 100;
	margin: auto;
	background: #3a3e52 !important;
	/* box-shadow: 0 10px 10px rgb(103 58 184 / 15%) !important; */
	border-top: 1px solid #F7F6F6;
	border-style: solid;
	border-width: 1px 0px 0px 0px;
	border-color: #FEA737;
}

img.win_bgg {
	width: 100%;
	height: 9.78667rem;
}

button.waves {
	background-image: linear-gradient(#f45050, #b20909);
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
	padding: 6px 27px;
	font-weight: 600;
	border: none;
	font-size: 13px;
	border-radius: 5px;
}

.live_text {
	font-weight: 400;
	font-size: 13px;
}

.today_text {
	font-size: 11px;
	font-weight: 400;
	position: relative;
	bottom: 10px;
}

span.main_zoom {
	color: #f45050;
	font-weight: 600;
	font-size: 16px;
	position: relative;
	bottom: 10px;
}

h4.remaining {
	font-size: 11px;
	line-height: 25px;
	padding: 6px;

}

.tab-btn {
	border: none;
}

.bg-transparent {
	background-color: transparent !important;
}

div#tabbar1 {
	padding-bottom: 100px;
}

.main-part,
.main-section,
.content {
	margin-top: 60px;
}

a.col-3.w3-bar-item.w3-button.bg-transparent.tab-btn {
	font-size: 11px;
	font-weight: 500;
	color: #fff;
	padding-top: 6px;
	padding-bottom: 6px;
}

a.col-2.w3-bar-item.w3-button.bg-transparent.tab-btn {
	font-size: 11px;
	font-weight: 500;
	color: #fff;
	/*	padding-top: 5px;*/
	padding-bottom: 5px;
	margin: auto;
	text-decoration: none;
}

span.span_up {
	display: block;
	color: #fff;
	font-size: 10px;
}

.back_top_head {
	background: #626cd2;
	box-shadow: 0px 0px 4px #a79999;
	padding: 10px 0;
}

.top_icon i {
	color: #fff;
	font-size: 20px;
	margin-left: 30px;
}

h4.textt {
	color: white;
}

.owl-theme .owl-dots .owl-dot span {
	width: 5px !important;
	height: 5px !important;
}

img.welcome {
	width: 140px;
	/* margin-left: 10px; */
	height: 50px;
	/* margin-bottom: 9px; */
	position: relative;
	/* top: 10px; */
	bottom: 8px;
}

.navbar__content {
	position: fixed;
	/* bottom: 0px; */
	max-width: 100%;
	width: 650px;
	left: 50%;
	display: -webkit-flex;
	align-items: flex-end;
	transform: translate(-50%);
	height: 55px;
	z-index: 100;
	margin: auto;
	background: #3a3e52 !important;
	/* box-shadow: 0 10px 10px rgb(103 58 184 / 15%) !important; */
	/* border-top: 1px solid #F7F6F6; */
	border-style: solid;
	border-width: 0px 0px 1px 0px;
	border-color: #FEA737;
	/* border-radius: 25px 25px 25px 25px; */
	/* box-shadow: 0px 0px 2px 0px #F4E706; */
	/* padding: 20px 80px 20px 80px; */

}

.content__right {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	position: relative;
	bottom: 18px;
	color: white;
	left: -9px;

}

i.fa-solid.fa-arrow-left {
	display: flex;
	/* position: absolute; */
	position: relative;
	left: 21px;
	top: 17px;
	margin: 0px;
	background: #1C2033;
	width: 30px;
	padding: 10px 10px 10px 10px;
	height: 30px;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	color: #FEC044;
}

.content__right span {
	color: #fff;
	font-size: 10px;
	letter-spacing: 0;
	font-weight: bold;
}

.navbar__content-right {
	position: absolute;
	right: 0.32rem;
}

img.down {
	font-size: .53333rem;
	margin-top: -0.13333rem;
	width: 25px;
	height: 25px;
	margin-left: 1.64rem;
}

a {
	text-decoration: none;
}

.progress {
	width: 150px;
	height: 150px;
	line-height: 150px;
	background: none;
	margin: 0 auto;
	box-shadow: none;
	position: relative;
}

.progress:after {
	content: "";
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 14px solid #eee;
	position: absolute;
	top: 0;
	left: 0;
}

.progress>span {
	width: 50%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	z-index: 1;
}

.progress .progress-left {
	left: 0;
}

.progress .progress-bar {
	width: 100%;
	height: 100%;
	background: none;
	border-width: 14px;
	border-style: solid;
	position: absolute;
	top: 0;
	border-color: red;
}

.progress .progress-left .progress-bar {
	left: 100%;
	border-top-right-radius: 75px;
	border-bottom-right-radius: 75px;
	border-left: 0;
	-webkit-transform-origin: center left;
	transform-origin: center left;
}

.progress .progress-right {
	right: 0;
}

.progress .progress-right .progress-bar {
	left: -100%;
	border-top-left-radius: 75px;
	border-bottom-left-radius: 75px;
	border-right: 0;
	-webkit-transform-origin: center right;
	transform-origin: center right;
}

.progress .progress-value {
	display: flex;
	text-align: center;
	line-height: 20px;
	align-items: center;
	margin: auto;
}

.progress .progress-value div {
	margin-top: 10px;
}

.progress .progress-value span {
	font-size: 12px;
	text-transform: uppercase;
	color: #fff;

}

.progress[data-percentage="40"] .progress-right .progress-bar {
	animation: loading-4 1.5s linear forwards;
}

.progress[data-percentage="40"] .progress-left .progress-bar {
	animation: 0;
}

@keyframes loading-4 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(144);
		transform: rotate(144deg);
	}
}

.ifc {
	background: linear-gradient(to right, #ff000026, #00000026);
}

/*li.btnn_blue {
	background-image: linear-gradient(#f450500d, #b2090969) !important;
	box-shadow: 0 3px 1px 0 rgb(255 0 0 / 45%);
	border: 1px #ef7f7f solid;
	border-radius: 7px;
	padding: 8px 10px;
	width: 77px;
	text-align: center;
}*/

.win {
	width: 100%;
	border: 1px #f45050 solid;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	background-image: linear-gradient(#f450500d, #b2090969);
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

img.win_bg {
	width: 100%;
	height: 7.78667rem;
}

span.main_span {
	color: #fff;
	font-weight: 400;
	font-size: 12px;
}

.win_text {
	font-size: 17px;
	font-weight: 600;

}

.ifc_text {
	font-weight: 400;
	color: gray;
	font-size: 13px;
	margin-top: 6px;
	text-align: end;

}

h4.main_div {
	font-size: 11px;
	line-height: 25px;
	padding: 5px;
}

.zoom_box {

	background-image: linear-gradient(#f450500d, #b2090969) !important;
	box-shadow: 0 3px 1px 0 rgb(255 0 0 / 45%);
	border: 1px #ef7f7f solid;
	border-radius: 7px;
}

button.check_btn {
	font-weight: 600;
	background-image: linear-gradient(#f45050, #b20909);
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
	padding: 10px 11px;
	font-size: 13px;
	border: none;
	border-radius: 5px;
}

.international {
	color: #f45050;
	font-size: 20px;
	font-weight: 600;
}

h2.card-visa {
	font-size: 20px;
}

.debit {
	border: 1px #f3f95957 solid;
	border-radius: 4px;
}

.debit_card {
	/* background-image: url('img/debit-card.png'); */
	background-size: cover;
	height: 150px;

}

.zoom_meet {
	padding: 5px;
	display: flex;
	flex-direction: column;
}

.section-title {
	color: #f45050;
	font-size: 18px;
	width: 100%;
	text-align: center;
	font-weight: 600;
	padding-top: 10px;
	background-image: linear-gradient(#f450500d, #b2090969) !important;
	box-shadow: 0 3px 1px 0 rgb(255 0 0 / 45%);
	border: 1px #ef7f7f solid;
	border-radius: 7px;
	border-radius: 5px;
}

.theme-secondary-color {
	color: #ff8700;
}

.stats ul {
	display: flex;
	margin: 0px;
	justify-content: space-between;
	text-align: center;
}

.stats span {
	font-weight: 500;
	padding-top: 7px;
	display: block;
	font-size: 17px;
	text-align: center;
	color: white;
	position: relative;
	top: 9px;
}

li.stats_li {
	background: transparent;
	width: 100%;
	text-align: center;
	padding: 6px;
}

img.win_logo {
	width: 100%;
	height: 100%;
	max-width: 215px;
	margin-top: 7px;
}

.reward i {
	color: #fff;
}

p.bonus {
	color: #d2d2d2;
	font-size: 10px;
	font-weight: 600;
	position: relative;
	top: 10px;


}

li.stats_li.br-right {
	border-right: 1px #f9595957 solid;
}

.top {
	border-bottom: 1px #f9595957 solid;
	padding: 10px;

}

li.stats_li.br-right {
	border-right: 1px #f9595957 solid;
}

.btm-br {
	border-bottom: 1px #f9595957 solid;
}

h2.my {
	color: #f45050;
	padding: 8px;
	font-weight: 600;
	font-size: 18px;
	text-align: center;
}

img.zoom_logo {
	height: 132px;
	padding: 20px;
}

.main_container_box {
	margin-top: 60px;
}

span.x-icon {
	color: #11ff11;
}

.product_box ul {
	display: flex;
	margin: 0px;
	gap: 10px;
	justify-content: space-between;
	text-align: center;
}

li.our-project {
	background: #5e96f0;
	border-radius: 7px;
	padding: 8px 10px;
	width: 100%;
	text-align: center;
}

a.col-3.w3-bar-item.w3-button.bg-transparent.tab-btn img {
	width: 30px;
}

a.col-2.w3-bar-item.w3-button.bg-transparent.tab-btn img {
	width: 22px;
	margin-top: 5px;
}

a.inner-btn img {
	width: 29px;
}

span.reward img {
	width: 50px;
	height: 50px;
}

img.image_border {
	width: 100%;
	border-radius: 7px;
	height: 240px;
}



button.transfer {
	background-image: linear-gradient(#f45050, #b20909);
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
	padding: 6px 27px;
	color: #fff;
	font-weight: 600;
	border: none;
	font-size: 13px;
	border-radius: 5px;
	margin-top: 5px;
}

button.transfer_stake {
	background: transparent;
	padding: 6px 27px;
	color: #fff;
	font-weight: 600;
	border: 1px solid #f45050;
	margin-top: 5px;
	font-size: 13px;
	border-radius: 5px;
}

h2.my__stats {
	color: #f45050;
	padding: 10px;
	font-weight: 500;
	font-size: 16px;
	text-align: center;
}

span.bonus {
	color: #000;
	font-size: 15px;
	font-weight: 500;
}

.stake span {
	display: block;
	text-align: center;
}

li.stake_li.br-right {
	border-right: 1px #bda0f0 solid;
}

.stake ul {
	display: flex;
	margin: 0px;
	justify-content: space-between;
	text-align: center;
	/*	margin-top: 90px;*/
	background: #f2ecfc !important;
	border-radius: 8px;
	box-shadow: 0 10px 10px rgb(103 58 184 / 15%) !important;
	position: relative;
}

.form-group {
	background: transparent !important;
	border-radius: 8px;
	/* box-shadow: 0 10px 10px rgb(103 58 184 / 15%) !important; */
	padding: 5px;
	color: black;
	text-align: center;
}

button.p-2.bg-success.text-white {
	background-image: linear-gradient(280deg, #0B70DB 0%, #FEBF43 85%);
	border: none;
	color: black !important;
	border-radius: 4px;
	font-weight: 500;
}

label {
	text-align: left;
	display: block !important;
}

.exchange-1 p {
	margin: 0px;
	/* padding: 0px; */
}

label.vehicle-no {
	font-weight: 300;
	font-size: 12px;
	color: #585468;
}

span.coin_0 {
	color: #434343;
	font-size: 12px;
	text-align: center;
}

span.stake img {
	width: 48px;
	height: 48px;
}

li.stake_li {
	background: transparent;
	width: 100%;
	text-align: center;
	padding: 6px;
	position: relative;
	bottom: 10px;

}

.max_up.d-flex.align-items-center {
	justify-content: space-around;
	background: linear-gradient(to right, #ff000026, #00000026);
	margin-top: 20px;
}

.coin__coin.br-right {
	border-right: 1px #f9595957 solid;
	width: 50%;
	text-align: center;
	padding: 6px;
}

.data_coin {
	border-right: 1px #f3f95957 solid;
	width: 50%;
	text-align: center;
	padding: 6px;
}

img.no-stake {
	width: 100%;
}

div#tabbar2 {
	padding-bottom: 100px;
}

p.available {
	color: #fff;
}

.luckyWinners__container-wrapper__item {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	margin-bottom: 0.13333rem;
	padding: 7px 0.24rem;
}

img.gameTypeImg {
	width: 80px;
	height: 100%;
	max-width: 100%;
	-webkit-box-flex: 0;
	-webkit-flex: none;
	flex: none;
}

h1.data-v-c6b1fe62 {
	color: #686868;
	font-size: 14px;
	margin: 0px;
}

button.watcho-btn {
	padding: 0px 3px;
	color: white;
	border: none;
	background: green;
	border-radius: 17px;
	margin-right: 4px;
}

span.data-v {
	color: green;
	font-size: 12px;
	font-weight: 400;
}

.luckyWinners__container-wrapper__item {
	display: flex;
	/*	justify-content: space-between;*/
	background: #f2ecfc !important;
	border-radius: 8px;
	box-shadow: 0 10px 10px rgb(103 58 184 / 15%) !important;
	position: relative;
	top: 8px;
}

h1.Recive_9 {
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 0.13333rem;
	text-shadow: none;
	color: #fff;
}

span.winni_am0 {
	font-size: 17px;
	font-weight: 600;
	color: #c1c1c1;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

i.fas.fa-arrow-up {
	text-align: center;
	transform: rotate(45deg);
	width: 30px;
	height: 30px;
	color: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.luckyWinners {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	background-image: linear-gradient(#f450500d, #b2090969) !important;
	box-shadow: 0 3px 1px 0 rgb(255 0 0 / 45%);
	border: 1px #ffa8a8 solid;
	width: 100%;
	margin-bottom: 0.13333rem;
	padding: 1.26667rem 0.24rem;
	border-radius: 10px;
	display: flex;
}

.active {
	background: transparent;
	/* width: 100%; */
	text-align: center;
	/*    padding: 6px;*/
	/*   border: 1px solid red;*/
	border-radius: 10px;
}

.inactive ul {
	display: flex;
	margin: 0px;
	justify-content: space-between;
	text-align: center;
	gap: 10px;
}

.active span {
	color: #febd3f;
	text-decoration: underline;
	font-weight: 500;
	/*    padding-top: 7px;*/
	display: block;
	font-size: 10px;
	/* text-align: left; */

	text-decoration: none !important;

}

b {
	background: linear-gradient(to right, #FEC044, #cbe4ff);
	background-clip: border-box;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent !important;
}

.active_img {
	border-radius: 50%;
	margin: auto;

}

/*span.active img {
      width: 37px;
      height: 37px;
  }*/
p.active_p {
	color: #d2d2d2;
	font-size: 20px;
	font-weight: 600;
	text-align: left;
}

span.active_img img {
	/*    width: 37px;
      height: 37px;*/
	display: flex;
	margin: auto;
}

.luckyWinners__container-wrapper__item-winAmount {
	margin-left: 10px;
}

span.winni_staking {
	font-size: 17px;
	font-weight: 600;
	color: #c1c1c1;
}

div#tabbar3 {
	padding-bottom: 100px;
}

span.admin_1 {
	font-size: 14px;
	font-weight: 500;
	color: white !important;
}

.navbar__content-left {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-left: 15px;
}

h6.welcome {
	font-size: 12px;
}

h2.self.text-left {
	text-align: left;
	font-size: 15px;
}

p.self_pre {
	color: #d2d2d2;
	font-size: 10px;
	font-weight: 600;
	text-align: initial;
}

img.fortune_pic {
	width: 39px;
}

p.no-stake {
	font-size: 12px;
	color: #fff;
	margin: 0;
}

img.welcome1 {
	width: 50px;
}

.align-items-center {
	align-items: center !important;
	flex-direction: column;
	text-align: center;
}

.card-body.d-flex.p-0 {
	justify-content: space-evenly;
	align-items: center;
}

h6#paidTeam {
	text-align: center;
	font-size: 13px;
	margin: 0;
	color: #fff;
}

span.last_text {
	color: #b2b2b2;
	font-size: 15px;
	text-align: center;
}

div#tabbar4 {
	padding-bottom: 100px;
}

.icon-x {
	display: flex;
	align-items: center;
}

.max_up.d-flex.align-items-center {
	flex-direction: inherit;
}


p.daily_p {
	text-align: center;
	font-size: 11px;
	color: #fff;
	margin: 0;
}

.daliy_R {
	background-image: linear-gradient(#f45050, #b20909);
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
	padding: 7px;
	border-radius: 5px;
}

span.icon_arrow {
	color: white;
	text-align: center;
	margin-left: 7px;
	/* padding: 5px; */
	display: inline-table;
}

.card.Stake {
	background-image: linear-gradient(#f450500d, #b2090969) !important;
	box-shadow: 0 3px 1px 0 rgb(255 0 0 / 45%);
	border: 1px #ef7f7f solid;
	border-radius: 7px;
	padding: 8px 10px;
	border-radius: 6px;
	width: 100%;
	text-align: center;
}

span#paidTeam_span {
	color: #fff;
}

img.fortune_picture {
	width: 39px;
	border-radius: 6px;
}

.icon-xx {
	display: flex;
	align-items: center;
	align-items: center;
	margin-top: 12px;
	justify-content: space-between;
}

p.Balance_p {
	color: #d2d2d2;
	font-size: 10px;
	font-weight: 600;
	text-align: initial;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

h2.Balance_h2 {
	text-align: left;
	font-size: 15px;
	text-align: left;
	font-size: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Balance {
	border-bottom: 1px #f9595957 solid;
	padding: 10px;
}

span.self_icon {
	text-align: end;
	display: flex;
	justify-content: end;
	align-items: center;
	gap: 10px;
}

span.self_icon {
	font-size: 16px;
	color: #fff;
	font-weight: 400;
}

span.winni_am1 {
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	display: flex;
	align-items: end;
	gap: 6px;
}

/*sidebar*/


#wrapper {
	padding-left: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

#wrapper.toggled {
	padding-left: 250px;
}

#sidebar-wrapper {
	z-index: 1000;
	position: fixed;
	right: 0px;
	width: 0;
	height: 100%;
	margin-left: 0px;
	overflow-y: auto;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	/*      margin-top: 25px;*/
}

#wrapper.toggled #sidebar-wrapper {
	width: 250px;
}

#page-content-wrapper {
	width: 100%;
	position: absolute;
	padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
	position: absolute;
	margin-right: -250px;
}

.sidebar-nav {
	position: absolute;
	top: 210px;
	width: 350px;
	margin: 0;
	padding: 0;
	list-style: none;
	margin-left: 15px;
	height: 100%;
}

.sidebar-nav li {
	/* text-indent: 20px; */
	line-height: 40px;
	list-style: none;
	position: relative;
	transition: all .5s ease;
}

.sidebar-nav li a {
	padding: 0 20px;
	font-size: 13px;
	color: white;
	display: flex;
	align-items: center;
	font-weight: 500;
	text-decoration: none;
	margin: 2px 0;
	border-radius: 10px;
	white-space: nowrap;
	display: block;
	position: relative;
	transition: all .5s ease;

}

/* .sidebar-nav li a:hover {
      text-decoration: none;
      background: rgba(255,255,255,0.2);
  } */

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
	text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
	height: 65px;
	font-size: 18px;
	line-height: 60px;
}

.sidebar-nav>.sidebar-brand a:hover {
	background: none;
}

@media(min-width:768px) {
	#wrapper {
		padding-left: 250px;
	}

	#wrapper.toggled {
		padding-left: 0;
	}

	#sidebar-wrapper {
		width: 0px;
	}

	#wrapper.toggled #sidebar-wrapper {
		width: 250px;
	}

	#page-content-wrapper {
		padding: 20px;
		position: relative;
	}

	#wrapper.toggled #page-content-wrapper {
		position: relative;
		margin-right: 0;
	}
}

#wrapper.toggled #sidebar-wrapper {
	width: 390px;
}

.btn-default {
	background-color: transparent !important;
	border-color: transparent !important;
}

.sidebar.s-left {
	top: 0;
	right: 0;
	background-color: #041c32;
}

li.sidebar-brand {
	background-color: #042433;
	color: #fff;
	transition: all .5s linear;
}

.sidebar ul li a i {
	line-height: 50px;
	font-size: 22px;
	transition: all .4s linear;
	width: 50px;
	margin-right: 10px;
}

i.fa.fas.fa-home::before {
	background: transparent;
	padding: 1px;
	font-size: 22px;
}

span.link-name {
	font-family: "Chakra Petch", sans-serif !important;
	font-size: 15px;
	white-space: nowrap;
	line-height: 50px;
	list-style: none;
}

/* .sidebar ul li a:hover {
          background-image: linear-gradient(90deg, red, black);
        padding-left: 30px;
      } */
.sidebar ul li {
	margin: 8px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 14px
}

li.text-icon {
	border: 1px solid #fff;
	border-radius: 6px;
	width: 364px;
}

.sidebar ul li a:hover i {
	color: #fff;
}

.sidebar ul li {
	/* position: relative; */
	transition: all .5s ease;
}

.title-text {
	font-weight: 300;
	line-height: 1.2;
	font-size: 30px;
	padding-bottom: 0.4em;
}

.text-container {
	background: white;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	min-height: 100vh;
	padding: 40px 48px;
	/* / align-items: center; */
	justify-content: center;
}

p#errorShortDesc {
	font-size: 1.15em;
	line-height: 1.3;
	font-weight: 400;
	margin-top: 10px;
}

span#found-hint-header {
	font-size: 15px;


}

body.night .card.border-1 {
	color: white;
}


/*  */
.side-icon img {
	max-width: 15px;
	/*	filter: invert(1);*/
}

.main-icon img {
	width: 28px;
}

.main-icon i {
	color: #ffff;
	font-size: 25px;
}

.main-icon {
	display: flex;
	align-items: center;
}

div#sidebar-wrapper {
	background: #007aff;
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.four-icon {
	padding: 12px 14px;
	text-align: center;
	margin-bottom: 35px;
}

.four-icon i:hover {
	background: #fff;
	color: red;
	transform: scale(1.1);
}

.four-icon i {
	border: 2px solid #fff;
	padding: 10px 12px;
	border-radius: 50%;
	font-size: 20px;
	color: #fff;
	margin-left: 20px;
	transition: all 0.3s linear;
}

.admin h2 a {
	font-size: 15px;
	color: #fff;
	text-decoration: initial;
}

.text-icon:hover {
	background: transparent;
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
	padding-left: 30px
}

.header {
	position: relative;
	display: flex;
	justify-content: space-around;
	background: #fff;
	border-radius: 0 0 0 50px;
	padding: 5px 14px;
	align-items: center;
	justify-content: center;
	top: 27px;
}

img.rounded {
	position: absolute;
	top: 0;
	left: 20px;
	max-width: 50px;
	padding: 20px 0px;
}

.admin h2 a {
	font-size: 15px;
	color: #000;
	text-decoration: initial;
}

.side-icon i {
	transform: rotate(307deg);
	color: white;
}

i.fas.fa-wallet {
	font-size: 22px;
	margin-top: -0.13333rem;
}

i.fas.fa-bars {
	font-size: 22px;
	margin-top: -0.13333rem;
}

i.far.fa-bell {
	font-size: 22px;
	margin-right: 10px;
}

h4.rubik {
	font-size: 17px;
	padding: 10px;

}

small.create_new {
	font-size: 15px;
	padding: 10px;

}

.content-body-page {

	margin-top: 67px;
}

.card-body.Compose {
	background-image: linear-gradient(#f450500d, #b2090969) !important;
	box-shadow: 0 3px 1px 0 rgb(255 0 0 / 45%);
	border: 1px #ef7f7f solid;
	border-radius: 7px;
	/* margin-top: 67px; */
	padding: 10px;
}

.form-control:focus {
	box-shadow: 0 0 0 0.25rem rgb(200 188 235 / 50%);
}

.form-control {
	height: 42px;
	text-align: left;
	max-width: 100%;
}

h2 {
	margin: 0;
}

img.gift-threebox {
	width: 30px;
}

.tabbar__container-item[data-v-df3dcb90] {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	flex: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
	position: relative;
}

.promotion {
	width: 60px;
	height: 60px;
	padding-top: 0;
	text-align: center;
	background-size: cover;
	background-image: url(https://damangames.in/assets/png/promotionBg-7062bc98.png);
	position: absolute;
	top: 0px;
	right: 0;
	left: 0;
	bottom: 70px;
	display: flex;
	margin: auto;

}

p.home-p {
	font-size: 12px;
	position: relative;
	top: 25px;
}

/*p.home-p {
    margin: -7px;
    font-size: 14px;
        margin-right: 10px;
}*/
/*.tabbar__container-item>div.promotion[data-v-df3dcb90] {
    width: 1.6rem;
    height: 2.4rem;
    padding-top: 1.97333rem;
    text-align: center;
    background-size: 1.94667rem;
    background-image: url(https://damangames.in/assets/png/promotionBg-7062bc98.png);
}*/

.text-section {
	display: flex;
	align-items: center;
}

.card img {
	width: 35px;
	margin: auto;
	padding: 0.5em;
	border-radius: 0.7em;
	margin-top: -5px;
	margin-right: 5px;

}

.anticon {
	color: white !important;
}

.form-control {

	color: #fff !important;
	appearance: none;
	background-color: transparent !important;

}

.user-text {
	color: white !important;
}

.btn {
	background: #ffffff;
}

.card {
	flex-direction: row;

}

.card.dark {
	border-radius: 7px;
	padding: 0px 4px;
	text-align: center;
	background: #f2ecfc !important;
	border-radius: 8px;
	box-shadow: 0 10px 10px rgba(0, 10, 10, .1) !important;
}

a.btn.btn-light {
	background: transparent;
	border: none;
}

img.arrow_left {
	width: 30px;
}

.image_border {
	background: transparent;
	width: 43px;
	height: 40px;
	border-radius: 26%;
	align-items: center;
	margin: auto;
	justify-content: center;

}

span.qr-my {
	font-weight: 700;
}

.cta-section button {
	font-size: 12px;
	background: #6739b7;
	border-radius: 15px;
	padding: 4px 10px;
	border: none;
	color: white;
}

img.upi-img {
	width: 100px;
}

.pay-bills.d-flex.justify-content-center.text-center {
	height: 40px;
	align-items: center;
}

img.bill_pay-img {
	width: 40px !important;
}

img.see-all {
	width: 16px !important;
	display: flex;
}

img.Links-img {
	width: 46px !important;
	border-radius: 10px;
}

img.Bookings {
	width: 34px !important;
}

.plan-book {
	display: flex;
	width: 50px;
	height: 50px;
	border: 1px solid #d8d1f4;
	border-radius: 14px;
	justify-content: center;
	align-items: center;
	margin: auto;
}

img.Book-Switch {
	width: 46px !important;
	border-radius: 10px;
	height: 45px;
}

.card.dark.main-code {
	position: relative;
	top: 10px;
	background: #fff !important;
}

img.stores-img {
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

h2.stores-h2 {
	font-size: 17px;
	font-weight: 400;
	text-align: center;
}

button.stores-btn {
	border-radius: 25px;
	background: #6639b7;
	color: white;
	border: none;
	padding: 5px 20px;
	margin: auto;
	display: block;
	margin-top: 16px;
}

.sysMessage__container-msgWrapper__item {
	padding: 0.6667rem;
	border-radius: 0.06667rem;
	background: #fff;
	box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
	position: relative;
	top: 36px;
	border-radius: 7px;
}

.sysMessage__container-msgWrapper__item-title[data-v-4991efa4] {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
}

.sysMessage__container-msgWrapper__item-title svg[data-v-4991efa4] {
	width: 20px;
	height: 20px;
}

.sysMessage__container-msgWrapper__item-time[data-v-4991efa4] {
	margin-block: 0.10667rem 0.37333rem;
	color: #888;
	font-size: 14px;
	font-weight: 400;
	margin-top: 10px;

}

.sysMessage__container-msgWrapper__item-content[data-v-4991efa4] {
	color: #666;
	font-size: .32rem;
	font-weight: 400;
	word-break: break-word;
}

.sysMessage__container-msgWrapper__item-title img[data-v-4991efa4]:last-of-type {
	width: 20px;
	height: 20px;
	margin-left: auto;
}

.sysMessage__container-msgWrapper__item-title img {
	width: 30px;
}

img.notice-img {
	margin-left: auto;
}

span.data-v-4991efa4 {
	font-size: 14px;
	margin-left: 10px;
	color: black;
}

.luckyWinners {
	-webkit-box-align: center;
	align-items: center;
	background: #f2ecfc !important;
	border-radius: 8px;
	box-shadow: 0 10px 10px rgb(103 58 184 / 15%) !important;
	width: 100%;
	margin-bottom: 0.13333rem;
	padding: 1.26667rem 0.24rem;
	border-radius: 10px;
	display: flex;
	position: relative;
	top: 80px;
	border: none;
}

img.gameTypeImg {
	width: 40px;
	height: 100%;
	max-width: 100%;
	-webkit-box-flex: 0;
	-webkit-flex: none;
	flex: none;
	border-radius: 10px;

}

.luckyWinners__container-wrapper__item-winAmount {
	margin-left: 10px;
}

h1.Recive_9 {
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 0.13333rem;
	text-shadow: none;
	color: #373737;
}

span.winni_staking {
	font-size: 13px;
	font-weight: 600;
	color: #747474;
}

.form-control:placeholder {
	color: #747474 !important;
	background-color: transparent;
	border-color: #b32828;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgb(178 42 42 / 25%);
}

h5.add-h2 {
	font-size: 14px;
}

.tab-system {
	background: #f2ecfc !important;
	border-radius: 8px;
	box-shadow: 0 10px 10px rgb(103 58 184 / 15%) !important;
	padding: 10px;
	padding-bottom: 50px;

}

.nav-link {
	color: #494a4b;

}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	color: #6739b7;
	border-bottom: 3px solid #6739b7;
	background: transparent;
	border-radius: 0px;
}

ul#pills-tab {
	display: flex;
	justify-content: space-around;
}

h4.send_money {
	font-size: 16px;
}

button.btn.btn-secondary {
	padding: 10px 13px;
	width: 100%;
	border-radius: 4px;
	background-image: linear-gradient(270deg, #FEC044 30%, #0F66C3 100%);
	border: none;
	font-size: 13px;
	margin: auto;
	display: flex;
	font-weight: 500;
	/* position: absolute; */
	/* 
	z-index: 999999; */
	justify-content: center;
	align-items: center;
	/* right: 0;
	left: 0;
	width: 100%; */
	color: #000;
}

.start-date {
	/* margin-top: 20px; */
	position: relative;
	top: 14px !important;
}

.card {
	background: #1c203373 !important;
	color: white !important;
}

.card-header.bg-white {
	background: #3a3e52 !important;
	border-radius: 3px !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-pagination .ant-pagination-item-active {
	font-weight: 600;
	background-color: #3a3e52;
	border-color: #fec044;
	color: white;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-pagination .ant-pagination-item {
	display: inline-block;
	min-width: 32px;
	height: 32px;
	margin-inline-end: 8px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	background-color: #2d3044;
	border: 1px solid #feb943;
	border-radius: 6px;
	outline: 0;
	cursor: pointer;
	color: white;
	user-select: none;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-pagination .ant-pagination-item-active:hover {
	border-color: #FEC044;
}

h6.dont-have {
	font-size: 12px;
	font-weight: 300;
	color: #515152;
	margin-top: 20px;
}

h5.fa-send {
	color: #6639b7;
	font-size: 14px;
	font-weight: 700;
	margin-top: 10px;

}

.main-btn-1 ul {
	display: flex;
	margin: 0px;
	justify-content: space-between;
	text-align: center;
}

.main-btn-1 {
	padding: 8px 10px;
	text-align: center;
	background: #f2ecfc !important;
	border-radius: 8px;
	box-shadow: 0 10px 10px rgb(103 58 184 / 15%) !important;
}

.main-btn-1 span {
	font-weight: 500;
	display: block;
	font-size: 12px;
	color: #373737 !important;
	margin-left: 8px;
	margin-top: 5px;
}

.input-group.mb-2.border.rounded-pill.p-1 {
	padding: 0px !important;
	margin-top: 10px;
	border: 1px solid #584e66;
	background: #f2ecfc;
}

.form-control:focus {
	box-shadow: none;
}

.form-control-underlined {
	border-width: 0;
	border-bottom-width: 1px;
	border-radius: 0;
	padding-left: 0;
}

.form-control::placeholder {
	font-size: 0.95rem;
	color: #aaa;
	font-family: sans-serif;
}

.input-group .btn {
	position: relative;
	z-index: 2;
	color: #574e66 !important;
	top: 9px;
}

i.fas.fa-arrow-up {
	text-align: center;
	transform: rotate(316deg);
	width: 30px;
	height: 30px;
	color: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #6739b7;
}

.loan-ins {
	position: relative;
	padding-bottom: 8px;
	border: none;
	/*    border-bottom: 1px solid #bdb1d059;*/

}

.for-space-bet {
	justify-content: space-between;
	font-weight: 500;
	align-items: center;
}

.main-arrow {
	display: flex;
	align-items: center;
	gap: 20px;
}

img.free-upi {
	width: 36px;
}

h5.loan-paid {
	font-size: 15px;
	text-align: justify;
	color: black;
	margin: 1px;
}

p.debi-form {
	font-size: 13px;
	font-weight: 300;
	color: #494949;
	margin: 0px;
}

span.balance-pe {
	margin-top: 14px;
	font-size: 13px;
	font-weight: 400;
	color: #4e4d51;
}

span.inr-pe {
	font-size: 18px;
	font-weight: 600;
	color: #262626;
}

a span.blockchain {
	color: white;
}

h3.activate-wallet {
	text-align: left;
	font-size: 18px;
	font-weight: 600;
}

p.upgrade-apply {
	text-align: left;
	font-size: 14px;
	color: gray;
	font-weight: 400;
}

hr {

	margin: 1rem 0;
	color: inherit;
	border: 0;
	border-top: 1px solid #bdb1d082;
	opacity: 1 !important;
}


.openPositionsDiv .positionRow {
	font-size: small;
}

.carousel {
	position: relative !important;
	width: 650px;
	margin: auto;
	top: 15px;
	max-width: 100%;
}

.row.text-h2.m-auto {
	width: 650px;
	max-width: 100%;
	margin: auto;
	/* padding-bottom: 60px; */
}

.padding-form {
	padding-bottom: 60px;
	background-color: #3A3E52;
}

input[type="text"] {
	background: transparent;
	/* width: 83%; */
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: white;
	appearance: none;
	background-color: var(--bs-body-bg);
	background-clip: padding-box;
	border: var(--bs-border-width) solid var(--bs-border-color);
	border-radius: var(--bs-border-radius);
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

h3.marque {
	font-size: 13px;
	width: 650px;
	max-width: 100%;
	margin: auto;
	color: #febd3f;
}

.main-boz {
	/* display: flex;
	justify-content: space-between; */
	/* margin: auto; */
	/* margin-top: 20px !important; */
	color: white;
}

.box_card1 {
	display: flex;
	justify-content: space-between !important;
	padding: 5px;
	background: #1c203373;
	margin: 2px 0px;
	border: 1px solid #febd3f;
	border-radius: 10px;
	height: 150px;
}

.box_card2 {
	display: flex;
	justify-content: space-between !important;
	/* margin-top: 16px; */
	padding: 5px;
	background: #1c203373;
	margin: 2px 0px;
	border: 1px solid #febd3f;
	border-radius: 10px;
	height: 150px;
}

.card {
	width: 650px;
	margin: auto;
	max-width: 100%;
	margin-top: 12px;
}

.maiv-2.row {
	width: 650px;
	max-width: 100%;
	margin: auto;
	margin-top: 10px;
}

button.badge.btn.bg-success {
	float: left;
	margin-left: -13px;
	margin-top: -3px;
}

button.badge.btn.bg-danger {
	margin-left: -13px;
	margin-top: -3px;
}


.input-group {
	/* display: flex; */
	align-items: center;
	border: 1px solid #ccc;
	padding: 0px;
	border-radius: 4px;
}

.input-group input {
	border: none;
	outline: none;
	padding: 5px;
}

.input-group button {
	background: none;
	border: none;
	cursor: pointer;
}

.date-picker {
	margin-left: 10px;
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.user-count {
	margin-left: 10px;
	display: flex;
	align-items: center;
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.filter-btn button {
	margin-left: 10px;
	padding: 5px 5px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	background: transparent;
	color: white;
	font-size: 15px;
	display: flex;
	gap: 10px;
	align-items: center;
}

.icon_set-22 {
	position: absolute;
	right: 7px;
	top: 12.5px;
	color: white;
	font-size: 15px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	/* margin: 10px; */
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: nowrap !important;
	;
	align-items: stretch;
	width: 100% !important;
	max-width: 100%;
	border: 1px solid #FEBD3A;
}

.main-table-2.d-flex.mt-3 {
	padding: 10px;
}

.kyc-input {
	border: 1px solid #dee2e6;
	padding: 7px;
	border-radius: 5px;
}

select#status {
	border: 1px solid #FEBD3A;
	padding: 5px;
	border-radius: 4px;
	font-size: 13px;
	background: #2d3044;
	color: white;
}

.d-flex {
	justify-content: space-around !important;
}

.main-12 {
	/* display: flex;
	justify-content: space-around;
	margin: auto; */
	width: 100%;
	align-items: center;
	padding: 20px;
}

.main-table-2.mt-3 {
	display: flex;
	justify-content: space-around;
	padding-bottom: 10px;
	gap: 4px;
}


.d-grid.mt-3.gap-3 {
	padding-bottom: 100px;
}

.dashboard__wrap {
	overflow: hidden;
	background-color: #1C2033;
	/* height: 100vh; */
	min-height: 100vh;
}

i.fa-solid.fa-copy.fa-fade {
	color: white;
}

.icon_set-2 {
	position: absolute;
	right: 9px;
	top: 45px;
	color: white;
	font-size: 15px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.main-12 {
	padding-top: 10px;
}

.main-boz h6 {
	line-height: 16px;
	padding: 0px;
	font-size: 10px;
	font-weight: 400;
}

/* 
.main-boz h6 ::after {
	content: var(--e-nav-menu-divider-content, none);
	height: var(--e-nav-menu-divider-height, 35%);
	border-left: var(--e-nav-menu-divider-width, 2px) var(--e-nav-menu-divider-style, solid) var(--e-nav-menu-divider-color, #000);
	border-bottom-color: var(--e-nav-menu-divider-color, #000);
	border-right-color: var(--e-nav-menu-divider-color, #000);
	border-top-color: var(--e-nav-menu-divider-color, #000);
	-ms-flex-item-align: center;
	align-self: center;
} */


.rounded.p-2 {
	box-shadow: none !important;
	border: 2px solid #e8f0fe;
	border: 1px solid #febd3f;
}

h3.scan.text-center {
	font-size: 16px;
	background-color: #febd3f !important;
	padding: 5px;
	width: 630px;
	margin: auto;
	color: black;
	max-width: 100%;
	/* margin-top: 10px; */
	border-radius: 4px;
}

form {
	background: transparent;
	padding-bottom: 100px;
	padding: 20px 20px 30px 20px;
	border-radius: 5px;
}

.group-form {
	background: #3a3e52;
	width: 650px;
	margin: auto;
	padding-bottom: 80px;
	max-width: 100%;
}

i.fa.fa-search {
	color: white;
	margin-right: 9px;
}

.form-label {
	margin-bottom: .5rem;
	color: #fff;
}

h3.scan-2.text-center {
	font-size: 12px;
	margin-top: 10px;
}

h3.scan-copy.text-center {
	font-size: 12px;
	font-weight: 400;
	border: 1px solid #dee2e6;
	width: 353px;
	margin: auto;
	padding: 10px;
	border-radius: 5px;
	max-width: 100%;
}

.main-qr.mt-5.text-center {
	padding-bottom: 100px;
}

.exchange-1 h2 {
	font-size: 13px;
	display: flex;
}

.exchange-1 {
	overflow: scroll;
	position: relative;
}

option {
	background: #3a3e52;
}

.exchange-1::-webkit-scrollbar {
	display: none;
}


.exchange-1 {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.exchange-1 span {
	margin-left: 37px;
	text-align: left;
	display: flex;
	justify-content: right;
	position: absolute;
	left: 100px;
	font-family: sans-serif;
	font-size: 12px;
	margin-top: 2px;
}

i.bi.bi-copy {
	position: relative;
	left: 10px;
	/* right: 3%; */
	/* color: white; */
	top: -1px;
	/* display: flex
; */
	/* justify-content: end; */
}

.copyclipboard-btn {
	position: relative !important;
	left: -15px !important;
	right: 34%;
	color: white;
	top: -31px !important;
	display: flex;
	justify-content: end;
}

.col-form-label {
	padding-top: calc(.375rem + var(--bs-border-width));
	padding-bottom: calc(.375rem + var(--bs-border-width));
	margin-bottom: 0;
	color: white;
	font-size: inherit;
	line-height: 1.5;
}

.form-control {
	display: block;
	width: 100% !important;
	padding: 3px !important;
	font-size: 12px !important;
	border: 1px solid #FEBD3A !important;
}

.in_in {
	width: 100%;
}

.in_put_field {
	position: relative;
}

.icon_set {
	position: absolute;
	right: 6px;
	top: 4.5px;
	color: white;
	font-size: 15px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	/* margin: 10px; */
}

span.get-otp {
	font-weight: 500;
	font-size: 13px;
	/* display: flex; */
	margin-left: 5px;
}

input[type="text"] {
	background: transparent;
	width: 100%;
	height: 42px;
	/* border: 1px solid #fec044; */
}

dl.row.mb-0.openPositionsDiv {
	font-size: small;
}

.d-flex.mt-3.gap-1 {
	padding-bottom: 60px;
}

.card.neWCls {
	margin-top: 65px;
	font-size: 13px;
}

.transactions {
	padding-bottom: 30px;
}

dl.row.mb-0.positionRow {
	font-size: 11px;
}

form {
	background: transparent;
	padding-bottom: 100px;
	padding: 20px 20px 30px 20px;
	border-radius: 5px;
}

.forget.fw-bold.mt-2.pt-1.mb-0.text-right {
	display: flex;
	justify-content: end;
}

.d-flex.justify-content-center {
	padding-bottom: 20px;
	border: none;
	height: auto;
}

.main-box-5 {
	height: 100vh;
}

/* .login-column-div2 {
	box-shadow: 0 0px 1px 0 #febd3f;
	background-size: cover;
	background-repeat: no-repeat;
	border: 1px #febd3f solid;
	border-radius: 7px;
	padding: 8px 0px;
} */
.transfer-height {
	/* height: 100vh; */
	padding-bottom: 70px;
	min-height: 100%;
}

p {
	font-size: 13px;
	margin: 0px;
}

.market-table {
	width: 650px;
	max-width: 100%;
	margin: auto;
	padding-bottom: 45px;
	overflow: auto;
}

.market-table th {
	font-size: 12px;
	text-align: center;
	padding: 18px;
	/* border-bottom: 1px solid #c7c0c0; */
	background: #1c203373;
	border-radius: 2px;
	color: #fff;
}

th.text-left.symbol {
	text-align: left !important;
}

input.form-control.input.user-text::placeholder {
	color: #7d7d7d;
}

.in_in::placeholder {
	color: #7d7d7d;
}

/* .section2 {
	height: 100vh;
} */

span.coin-name {
	font-size: 12px;
	font-weight: 500;
	margin-left: 7px;
	text-align: center;
	color: #fff;
}

span.coin-tag-name {
	font-size: 13px;
	font-weight: 500;
	text-align: center;
}

td.up-trade {
	font-size: 12px;
	color: #22bb33 !important;
	font-weight: 500;
	text-align: center;
}

td.upp-2 {
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	color: #fff;
}

.markit-td1 img {
	width: 21px;
	/* margin-left: -1px; */
}

span.coin-name-1 {
	color: #007aff;
	background: #f3f2f2;
	border-radius: 5px;
	font-size: 12px;
	margin-left: 6px;
	font-weight: 700;
	text-align: center;
	padding: 2px 7px;
}

.markit-td1 {
	max-width: 100%;
	padding: 12px;
}

table.table-responsive.mb-4 {
	width: 650px;
	/* max-width: 100%; */
}

.market-table::-webkit-scrollbar {
	display: none;
}

.market-table {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

@media (min-width: 576px) {

	.container,
	.container-sm {
		max-width: 671px;
	}
}

button.btn-1.login-btn1.btn-cancel {
	border: none;
	border-radius: 5px;
	padding: 5px;
	font-size: 14px;
	background-color: #0F68C5;
	border-style: solid;
	border-width: 1px 1px 1px 1px;
	border-color: #FEA737;
	box-shadow: 0px 0px 10px 0px #F4E706;

}

.modal-dialog {
	padding: 13px;
}

button.btn-2.login-btnn {
	background-image: linear-gradient(270deg, #FEC044 30%, #0F66C3 100%);
	color: #000;
	border: none;
	border-radius: 5px;
	padding: 5px;
	font-size: 14px;
}

/* .place-2 {
	display: flex;
	justify-content: space-around;
} */


.button {
	background-image: linear-gradient(280deg, #0B70DB 0%, #FEBF43 85%);
	-webkit-border-radius: 10px;
	border-radius: 10px;
	border: none;
	color: #FFFFFF;
	cursor: pointer;
	display: inline-block;
	font-family: Arial;
	font-size: 20px;
	padding: 5px 10px;
	text-align: center;
	text-decoration: none;
	-webkit-animation: glowing 1500ms infinite;
	-moz-animation: glowing 1500ms infinite;
	-o-animation: glowing 1500ms infinite;
	animation: glowing 1500ms infinite;
}

@-webkit-keyframes glowing {
	0% {
		background-image: linear-gradient(280deg, #0B70DB 0%, #FEBF43 85%);
		-webkit-box-shadow: 0 0 3px #FEBF43;
	}

	50% {
		background-image: linear-gradient(280deg, #0B70DB 0%, #FEBF43 85%);
		-webkit-box-shadow: 0 0 30px #FEBF43;
	}

	100% {
		background-image: linear-gradient(280deg, #0B70DB 0%, #FEBF43 85%);
		-webkit-box-shadow: 0 0 3px #FEBF43;
	}
}

@-moz-keyframes glowing {
	0% {
		background-color: #007aff;
		-moz-box-shadow: 0 0 3px #FEBF43;
	}

	50% {
		background-color: #007aff;
		-moz-box-shadow: 0 0 30px #FEBF43;
	}

	100% {
		background-color: #007aff;
		-moz-box-shadow: 0 0 3px #FEBF43;
	}
}

@-o-keyframes glowing {
	0% {
		background-color: #007aff;
		box-shadow: 0 0 3px #FEBF43;
	}

	50% {
		background-color: #007aff;
		box-shadow: 0 0 30px #FEBF43;
	}

	100% {
		background-color: #007aff;
		box-shadow: 0 0 3px #FEBF43;
	}
}

@keyframes glowing {
	0% {
		background-color: #007aff;
		box-shadow: 0 0 3px #FEBF43;
	}

	50% {
		background-color: #007aff;
		box-shadow: 0 0 30px #FEBF43;
	}

	100% {
		background-color: #007aff;
		box-shadow: 0 0 3px #FEBF43;
	}
}

.true-false {
	width: 650px;
	margin-top: 20px;
	padding-bottom: 20px;
	max-width: 100%;
}

.true-fals-2 {
	background: #1c203373;
	padding-bottom: 100px;
	padding: 15px 15px 20px 15px;
	border-radius: 5px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.flashikig-btn {
	padding: 0px 20px 0px 22px;
	/* background: blue; */
	height: 45px;
	font-size: 12px;
	color: #000;

}

.main-qr {
	background: #3a3e52;
	width: 650px !important;
	margin: auto;
	color: white;
	/* min-height: 100vh; */
}

p.pack-amount {
	font-size: 12px;
	font-weight: 600;
	/* margin-left: 7px; */
	padding: 5px;
	margin: 1px;
	color: #fff;
}

ul.nav.nav-pills.nav-fill {
	width: 650px;
	margin: auto;
	max-width: 100%;
	padding: 8px;
}

h3.scan.text-center.comming-soon {
	background-image: linear-gradient(280deg, #0B70DB 0%, #FEBF43 85%);
}

.team-current {
	text-decoration: none;
	background-image: linear-gradient(280deg, #0B70DB 0%, #FEBF43 85%);
	border-style: solid;
	border-width: 1px 1px 1px 1px;
	padding: 5px;
	border-radius: 4px;
	font-size: 16px;
	font-weight: 600;
	width: 122px;
	/* color: white; */
}

li.nav-item.team-current-2 a {
	font-size: 16px;
	font-weight: 600;
	color: white;
}

li.team-current a {
	text-decoration: none;
	color: #000;
	font-size: 16px;
	font-weight: 600;
}

.modal-dialog {
	color: #febd3f !important;

}

.modal-content {
	background-color: #1c2033 !important;
}

/* 
button.btn-close {
	background: white;
} */

li.nav-item.direct-2 a {
	text-decoration: none;
	font-size: 16px;
	font-weight: 600;
	color: white;
}

a {
	color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
	text-decoration: none !important;
}

/* .place-2 {
	display: flex;
	justify-content: space-around;
} */

button.apply-btn {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	border: none;
	border-radius: 5px;
	padding: 9px;
	font-size: 14px;
	background-color: #0F68C5;
	border-style: solid;
	border-width: 1px 1px 1px 1px;
	border-color: #FEA737;
	box-shadow: 0px 0px 1px 0px #F4E706;
}


.back-card {
	padding: 20px;
}

.mt-5.w-full {
	width: 650px;
	background-color: #3a3e52 !important;
}

.pagination {
	padding-bottom: 50px;
	margin-top: 30px;
}

button.btn.btn-danger.m-auto.d-block {
	background-image: linear-gradient(270deg, #FEC044 30%, #0F66C3 100%);
	border: none;
	color: black;
	font-weight: 500;
	width: 100%;
}

i.fa-regular.fa-copy.copy-btnn {
	margin-left: 10px;
}

.start-date {
	position: relative;
	top: 7px;
}

.card.border-primary {
	position: relative;
	top: 13px;
}

a.link-danger {
	color: #febd3f !important;
}

.small.fw-bold.mt-2.pt-1.mb-0.text-center {
	color: white;
}

.input_eye {
	display: flex !important;
	position: relative;
}

.in_put_f {
	position: relative !important;
	width: 100%;
}

.eye_sec {
	position: absolute;
	right: 10px;
	top: 10px;
	color: white;
}

button.btn.btn-primary.d-block.m-auto {
	background-image: linear-gradient(270deg, #FEC044 30%, #0F66C3 100%);
	border: none;
	color: black;
	font-weight: 600;
}

p.smalls.fw-bold.pt-1.mb-0.text-center {
	color: white;
}

.link-danger:hover {
	color: #0F66C3 !important;
	text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

button.btn.btn-primary.d-flex.m-auto {
	background-image: linear-gradient(270deg, #FEC044 30%, #0F66C3 100%);
	color: black;
	font-weight: 600;
	border: none;
}

.form-control:focus {
	box-shadow: 0 0 0 .25rem rgb(254 192 68 / 8%) !important;
}

button.btn.iop.btn-primary {
	/* background-image: linear-gradient(270deg, #FEC044 30%, #0F66C3 100%); */
	background-image: linear-gradient(270deg, #FEC044 30%, #0F66C3 100%);
	color: black;
	font-weight: 600;
	border: none;
	width: 100%;
}


/* footer.css */

section {
	padding: 30px 0;
	min-height: 100vh;
}

.level-up {
	height: 320px !important;
	max-height: auto;
	background: #ffffff !important;
}

section.foreign_exchange {
	margin: 0px;
}

.w_ft {
	margin-top: 20px;
}

a,
a:hover,
a:focus,
a:active {
	text-decoration: none;
	outline: none;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.main-footer {
	position: relative;
	background: #1e2129;
}

.footer-content {
	position: relative;
	padding: 50px 0px 80px 0px;
}

.footer-content:before {
	position: absolute;
	content: '';
	background: url(https://i.ibb.co/jyRLrBZ/world-map.png);
	width: 744px;
	height: 365px;
	top: 50px;
	right: 0px;
	background-size: cover;
	background-repeat: no-repeat;
	animation-name: float-bob;
	animation-duration: 30s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	-webkit-animation-name: float-bob;
	-webkit-animation-duration: 30s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	-moz-animation-name: float-bob;
	-moz-animation-duration: 30s;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;
	-ms-animation-name: float-bob;
	-ms-animation-duration: 30s;
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: linear;
	-o-animation-name: float-bob;
	-o-animation-duration: 30s;
	-o-animation-iteration-count: infinite;
	-o-animation-timing-function: linear;
}

.footer-content .logo-widget {
	position: relative;
	margin-top: -5px;
}

.footer-content .logo-widget .footer-social li {
	position: relative;
	display: inline-block;
	margin-right: 9px;
}

.footer-content .logo-widget .footer-social li:last-child {
	margin-right: 0px;
}

.footer-content .logo-widget .footer-social li a {
	position: relative;
	display: inline-block;
	width: 42px;
	height: 42px;
	line-height: 42px;
	background: #2e3138;
	color: #9ea0a9;
	text-align: center;
	border-radius: 50%;
}

.footer-content .logo-widget .footer-social li a:hover {
	color: #ffffff;
	background: #00b093;
}

.footer-content .logo-widget .logo-box {
	margin-bottom: 25px;
}

.footer-content .logo-widget .text p {
	color: #9ea0a9;
	margin-bottom: 32px;
}

.footer-content .footer-title {
	position: relative;
	font-size: 24px;
	line-height: 35px;
	font-family: 'Playfair Display', serif;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 27px;
}

.footer-content .service-widget .list li {
	display: block;
	margin-bottom: 12px;
}

.footer-content .service-widget .list li a {
	position: relative;
	display: inline-block;
	color: #9ea0a9;
}

.footer-content .service-widget .list li a:hover {
	color: #00b093;
}

.footer-content .contact-widget p {
	color: #9ea0a9;
	margin-bottom: 15px;
}

.footer-content .contact-widget {
	/* margin-left: 90px; */
	position: relative;
}

.footer-content .contact-widget .footer-title {
	margin-bottom: 29px;
}

/** footer-bottom **/

.footer-bottom {
	position: relative;
	background: #13151a;
	padding: 25px 0px 22px 0px;
}

.footer-bottom .copyright,
.footer-bottom .copyright a,
.footer-bottom .footer-nav li a {
	position: relative;
	color: #9ea0a9;
}

.footer-bottom .copyright a:hover,
.footer-bottom .footer-nav li a:hover {
	color: #00b093;
}

.footer-bottom .footer-nav {
	position: relative;
	text-align: right;
}

.footer-bottom .footer-nav li {
	position: relative;
	display: inline-block;
	margin-left: 29px;
}

.footer-bottom .footer-nav li:first-child {
	margin-left: 0px;
}

.footer-bottom .footer-nav li:before {
	position: absolute;
	content: '';
	background: #9ea0a9;
	width: 1px;
	height: 14px;
	top: 7px;
	left: -18px;
}

.footer-bottom .footer-nav li:first-child:before {
	display: none;
}

.logo-box img {
	max-width: 220px;
}

ul.list {
	padding: 0px;
}

.main-trade {
	width: 649px;
	margin: auto;
	max-width: 100%;
	background-color: #3A3E52;
}

.row.w3-bar.w3-black.footer-tab.mt-5 {
	/* margin: 0px; */
	margin-left: 0px;
}

.image-ticket {
	display: flex;
	justify-content: center;
	align-items: center;
}

.message-11 {
	padding-bottom: 50px;
	/* height: 100vh; */
}

dt {
	font-weight: 500 !important;
	font-size: 10px;
}

dd {
	font-size: 10px;
}

a.set-text {
	color: black;
}

button.beat-fade {
	padding: 7px 9px;
	border-radius: 4px;
	background-image: linear-gradient(270deg, #FEC044 30%, #0F66C3 100%);
	border: none;
	font-size: 13px;
}

@media (min-width: 576px) {

	.container,
	.container-sm {
		max-width: 1100px !important;
	}
}

.Amount {
	color: #339d00;
}

.roundeded.mt-3.p-2 {
	margin: auto;
	width: 622px;
	max-width: 100%;
	border: 1px solid #febd3f;
	border-radius: var(--bs-border-radius) !important;
}

input[type="text"]::placeholder {
	color: white;
	font-size: 12px;
}

p.position-Row {
	font-size: 15px;
	font-weight: 500;
}

p.position-parg {
	color: #b9b9b9;
}

.in_put_field.d-flex.mar {
	justify-content: space-between !important;
}

.btn:focus-visible {
	color: var(--bs-btn-hover-color);
	background-color: transparent;
	box-shadow: none;
}

.login-formi.main-success {
	border-radius: 20px;
	background: #1c203373;
	text-align: center;
	padding: 20px 10px 30px 10px;
}

h5.success-h5 {
	color: white;
	margin-top: 20px;
}

p.success-p {
	color: white;
	padding: 0px;
	margin: 2px;
}

button.login-button {
	background-image: linear-gradient(270deg, #FEC044 30%, #0F66C3 100%);
	color: black;
	font-weight: 600;
	border: none;
	padding: 8px 20px 8px 20px;
	border-radius: 4px;
}

.page {
	background: #3A3E52;
	height: 100vh !important;
}

img.welcome-1 {
	width: 100%;
	/* margin-left: 10px; */
	/* height: 50px; */
	/* margin-bottom: 9px; */
	position: relative;
	/* top: 10px; */
	bottom: 8px;
}

i.fa-solid.fa-backward.fa-beat {
	/* color: red; */
	color: #febd3f !important;
}

button.btn.btn-danger.btnn-1 {
	width: auto !important;
}

button.btn.confirm-btnn\" {
	padding: 10px 13px;
	border-radius: 4px;
	background-image: linear-gradient(270deg, #FEC044 30%, #0F66C3 100%);
	border: none;
	font-size: 13px;
	font-weight: 500;
	justify-content: center;
	align-items: center;
	color: #000;
}

button.btn.confirm-btnn-2:hover {
	color: #fff;
}

button.btn.confirm-btnn-2 {
	display: block;
	font-weight: 400;
	border-radius: 5px;
	padding: 9px;
	font-size: 14px;
	background-color: #0F68C5;
	border-style: solid;
	border-width: 1px 1px 1px 1px;
	border-color: #FEA737;
	box-shadow: 0px 0px 1px 0px #F4E706;
}

i.fa-solid.fa-money-bill-transfer {
	color: white;
	margin-top: 6px;
}

@media only screen and (max-width: 500px) {
	img.image_border {
		height: 200px;
	}
}