* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.navbar_u {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

button.sidebar_btn {
    padding: 6px;
    border: none;
    width: 43px;
    border-radius: 10px;
    background: #fff;
    display: none;
}

ul.navbar-nav li a:hover {
    transition: all .3s;
    color: #0009;
}

a.hov_links {
    color: #00b093;
    font-family: "Mukta", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: flex;
    justify-content: center;
}

a.hov_links::after {
    content: " ";
    height: 2px;
    width: 0%;
    display: block;
    background: #fff;
    transition: .5s ease-in-out;
}

a.hove_links:hover::after {
    content: " ";
    height: 2px;
    width: 100%;
    display: block;
}

button.btn_signup {
    background-color: #00b093;
    color: #fff;
    padding: 2px;
    width: 95px;
    border: none;
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    margin: auto;
}

@media screen and (max-width: 992px) {
    button.sidebar_btn {
        display: block;
    }
}
