*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.foreign_exchange{
    margin-top: 60px;
}
.future_trade{
    margin-top: 60px;
}
.w_ft{
    margin-top: 60px;
}
.card{
    position: relative;
    overflow: hidden;
    border: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    
}
.card > .card_hover_div{
    position: absolute;
    top: 0px;
    background: #fff;
    display: none;
    bottom: 0px;
    height: 100%;
    transition: all 2s;
}
.card:hover .card_hover_div{
    display: block;
    bottom: 100%;
    
}
.faqs{
    margin-top: 60px;
}
.accordion_content{
    padding-top: 12px;
}
button.btn_accordion{
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background-image: linear-gradient(to right, #3326b1, #0ac6d4);
    color: #fff;
}
