*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.flex_contact{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
footer .container{
    padding-top: 50px;
    padding-top: 50px;
}
a.footer_icn{
    color: #0003;
}
a.footer_icn:hover{
    color: #000;
    transition: all .3s;
}